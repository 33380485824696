
import Navbar from 'react-bootstrap/Navbar'
import Nav from'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'

const Header = () => {
    return (
  <div className = 'grid-container'>
      <div className = 'header'>
      Abhishek Mangla
      </div>
  </div>
    )
}

Header.defaultProps = {
    title : 'default title'
}
export default Header