import './App.css';
import ReactGA from 'react-ga';
import { useEffect } from 'react';
import Header from './components/Header'
import Tasks from './components/Tasks'
import Title from './components/Title'

const trackingId = "G-HLHN6QZ28B"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);


function App(){
  return (
    <Header />
);
}

export default App;
